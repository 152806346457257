textarea {
    resize: vertical;
    max-height: 250px;
}

a:hover,
a:focus {
    text-decoration: none;
    color: auto;
}

body {
    font-size: 15px !important;
}

.MuiSelect-root,
.MuiFormLabel-root,
.MuiInputBase-input,
.MuiTooltip-tooltip,
.MuiTypography-body1,
.MuiAlert-message,
.MuiButtonBase-root {
    font-size: 15px !important;
}

.MuiTypography-body2 {
    font-size: 13.5px !important;
}

button:focus {
    outline: none !important;
}